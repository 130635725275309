import React from "react";
import ContentLoader from './content-loader';

const loading = props => {
  if (props.error) {
    return <div>Could not load page</div>;
  } else if (props.pastDelay) {
    return <ContentLoader />;
  } else {
    return null;
  }
};

export default loading;
