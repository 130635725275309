import React from "react";
import { Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import Loadable from "react-loadable";
import DefaultLoader from "components/loaders/default";

// create the browser history
const history = createBrowserHistory();

const Home = Loadable({
  loader: () => import("pages/home"),
  loading: DefaultLoader
});

const Test = Loadable({
  loader: () => import("pages/test"),
  loading: DefaultLoader
});

const UnconfirmedList = Loadable({
  loader: () => import("pages/ops/unconfirmed-list"),
  loading: DefaultLoader
});

const Routing = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route path="/test" component={Test} />
    <Route path="/unconfirmed-list" component={UnconfirmedList} />
  </Switch>
);

export { Routing, history };
