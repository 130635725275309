import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app";

const renderLocation = document.getElementById("root");

const render = (Component, Element) => {
  return ReactDOM.render(<Component />, Element);
};

if (renderLocation) {
  render(App, renderLocation);

  if (module.hot) {
    module.hot.accept("./app", () => {
      render(App, renderLocation);
    });
  }
}
