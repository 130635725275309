import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { history } from "config/routing";

export default (asyncReducers = {}) =>
  combineReducers({
    router: connectRouter(history),
    ...asyncReducers
  });
