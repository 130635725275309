import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    MUIDataTableHeadCell: {
      fixedHeader: {
        backgroundColor: '#CCC',
      },
      root: {
        padding: '0 10px',
        backgroundColor: '#CCC',
      }
    },
    MUIDataTableBodyCell: {
      root: {
        padding: '0 10px',
      }
    }
  }
});

export default theme;