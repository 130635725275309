import React from "react";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";

const LoadingContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const loading = () => {
  return (
    <LoadingContainer>
      <CircularProgress />
    </LoadingContainer>
  );
}

export default loading;